//import logo from './logo.svg';
//import './App.css';
import React, { useState, useEffect } from "react";
import {
  HashRouter,
  Switch,
  Route
} from "react-router-dom";
import { css } from '@emotion/css';
// import API from Amplify library
import { generateClient } from 'aws-amplify/api';
//import auth from aws amplify
// import { user } from 'aws-amplify/auth';
import { getUrl } from "aws-amplify/storage";

// import query definition
import { listPosts } from "./graphql/queries";

import Posts from './Posts';
import Post from './Post';
import Header from './Header';
import CreatePost from './CreatePost';
import CreateComment from './CreateComment';
import Button from './Button';

//auth
// src/App.js, import the withAuthenticator component and associated CSS
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import { getCurrentUser } from 'aws-amplify/auth';



// function App({ signOut }) {
//   const API = generateClient();
//   const [posts, setPosts] = useState([]);
//   const [email, setEmail] = useState([]);
//   useEffect(() => {
//     fetchPosts();
//     currentAuthenticatedUser();
//   }, []);

//   async function currentAuthenticatedUser() {
//     try {
//       const { username, userId, signInDetails } = await getCurrentUser();
//       const { sub, email_verified, email } = await fetchUserAttributes();
//       setEmail(email);
//       // console.log(`The username: ${username}`);
//       // console.log(`The userId: ${userId}`);
//       // console.log(`The signInDetails: ${signInDetails}`);
//     } catch (err) {
//       console.log(err);
//     }
//   }

//   async function fetchPosts() {
//     try {
//       const postData = await API.graphql({ query: listPosts });
//       setPosts(postData.data.listPosts.items);
//     } catch (err) {
//       console.log({ err });
//     }
//   }
//   return (
//     <div>
//       <h1>FAR User manager</h1>
//       <h2>Hello {email}</h2>
//       {posts.map((post) => (
//         <div key={post.id}>
//           <h3>{post.name}</h3>
//           <p>{post.location}</p>
//           <p>{post.description}</p>
//         </div>
//       ))}
//       <button onClick={signOut}>Sign out</button>
//     </div>

//   );
// }

// export default withAuthenticator(App);

function Router({ user, signOut }) {
  const API = generateClient();

  /* create a couple of pieces of initial state */
  const [showOverlay, updateOverlayVisibility] = useState(false);
  const [showOverlay2, updateOverlayVisibility2] = useState(false);
  const [posts, updatePosts] = useState([]);
  const [myPosts, updateMyPosts] = useState([]);

  /* fetch posts when component loads */
  useEffect(() => {
    fetchPosts();
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchPosts() {

    /* query the API, ask for 100 items */
    let postData = await API.graphql({ query: listPosts, variables: { limit: 100 } });
    let postsArray = postData.data.listPosts.items;

    /* map over the image keys in the posts array, get signed image URLs for each image */
    postsArray = await Promise.all(postsArray.map(async post => {
      const imageKey = (await getUrl({ key: post.image })).url.href;
      if(post.image!==null){
        post.image = imageKey;
      }
      return post;
    }));

    /* update the posts array in the local state */
    setPostState(postsArray);
  }

  async function setPostState(postsArray) {
    // const { email } = await fetchUserAttributes();
    const {username} = await getCurrentUser();
    const myPostData = postsArray.filter((p) => p.owner === username);
    updateMyPosts(myPostData);
    updatePosts(postsArray);
  }

  return (
    <>
      <HashRouter>
        <div className={contentStyle}>
          <Header />
          <hr className={dividerStyle} />
          <Button title="New Post" onClick={() => updateOverlayVisibility(true)} />
          <Button title="Comment Post" onClick={() => updateOverlayVisibility2(true)} />
          <Switch>
            <Route exact path="/" >
              <Posts posts={posts} />
            </Route>
            <Route path="/post/:id" >
              <Post />
            </Route>
            <Route exact path="/myposts">
              <Posts posts={myPosts} />
            </Route>
          </Switch>
        </div>
        <button onClick={signOut}>Sign out</button>
      </HashRouter>
      {showOverlay && (
        <CreatePost
          updateOverlayVisibility={updateOverlayVisibility}
          updatePosts={setPostState}
          posts={posts}
        />
    )}
      {showOverlay2 && (
        <CreateComment
          updateOverlayVisibility2={updateOverlayVisibility2}
          updatePosts={setPostState}
          posts={posts}
        />
    )}    </>
  );
}

const dividerStyle = css`
  margin-top: 15px;
`

const contentStyle = css`
  min-height: calc(100vh - 45px);
  padding: 0px 40px;
`

export default withAuthenticator(Router);