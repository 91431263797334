// CreateComment.js

import React, { useState } from 'react';
import { css } from '@emotion/css';
import Button from './Button';
import { v4 as uuid } from 'uuid';
import { generateClient } from 'aws-amplify/api'
import { createComment } from './graphql/mutations';
import { getCurrentUser } from 'aws-amplify/auth';

const API = generateClient();

/* Initial state to hold form input, saving state */
const initialState = {
    id: '',
  message: '',
  owner: '',
  postCommentsId: '',
  saving: false
};

export default function CreateComment({
  updateOverlayVisibility2, updatePosts, posts
}) {

  /* 1. Create local state with useState hook */
  const [formState, updateFormState] = useState(initialState)

  /* 2. onChangeText handler updates the form state when a user types into a form field */
  function onChangeText(e) {
    e.persist();
    updateFormState(currentState => ({ ...currentState, [e.target.name]: e.target.value }));
  }

//   /* 3. onChangeFile handler will be fired when a user uploads a file  */
//   function onChangeFile(e) {
//     e.persist();
//     if (! e.target.files[0]) return;
//     const fileExtPosition = e.target.files[0].name.search(/.png|.jpg|.gif/i);
//     const firstHalf = e.target.files[0].name.slice(0, fileExtPosition);
//     const secondHalf = e.target.files[0].name.slice(fileExtPosition);
//     const fileName = firstHalf + "_" + uuid() + secondHalf;
//     console.log(fileName);
//     const image = { fileInfo: e.target.files[0], name: fileName};
//     updateFormState(currentState => ({ ...currentState, file: URL.createObjectURL(e.target.files[0]), image }))
//   }

  /* 4. Save the post  */
  async function save() {
    try {
      const { message } = formState;
    //   postCommentsId = "057cb138-94f0-4ff9-b85b-5ed5cdfece25"
      if (!message) return;
      updateFormState(currentState => ({ ...currentState, saving: true }));
      const commentId = uuid();
      const {username} = await getCurrentUser();
      const commentInfo = { postCommentsId: "057cb138-94f0-4ff9-b85b-5ed5cdfece25",owner: username,message, id: commentId };
      console.log(commentInfo)

    //   await uploadData({key: formState.image.name, data: formState.image.fileInfo});
      await API.graphql({
        query: createComment, authMode: "userPool", variables: { input: commentInfo }
      });
    //   updatePosts([...posts, { ...commentInfo, postCommentsId: postId, image: formState.file, owner: username}]);
      updateFormState(currentState => ({ ...currentState, saving: false }));
      updateOverlayVisibility2(false);
    } catch (err) {
      console.log('error: ', err);
    }
  }

  return (
    <div className={containerStyle}>
        Commenting on post: {posts[0].id}
      <input
        placeholder="Message"
        name="message"
        className={inputStyle}
        onChange={onChangeText}
      />
      { formState.file && <img className={imageStyle} alt="preview" src={formState.file} /> }
      <Button title="Create New Comment" onClick={save} />
      <Button type="cancel" title="Cancel" onClick={() => updateOverlayVisibility2(false)} />
      { formState.saving && <p className={savingMessageStyle}>Saving comment...</p> }
    </div>
  )
}

const inputStyle = css`
  margin-bottom: 10px;
  outline: none;
  padding: 7px;
  border: 1px solid #ddd;
  font-size: 16px;
  border-radius: 4px;
`

const imageStyle = css`
  height: 120px;
  margin: 10px 0px;
  object-fit: contain;
`

const containerStyle = css`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 420px;
  position: fixed;
  left: 0;
  border-radius: 4px;
  top: 0;
  margin-left: calc(50vw - 220px);
  margin-top: calc(50vh - 230px);
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.125rem 0.25rem;
  padding: 20px;
`

const savingMessageStyle = css`
  margin-bottom: 0px;
`
