/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://o4ra62guqbfftka2kglx2atbmu.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-73bpmlmlz5h6tgmcgesmfc5j44",
    "aws_cognito_identity_pool_id": "eu-central-1:4bdc2673-f3b4-4da0-921d-558bf39bbd82",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_TNr0exSm6",
    "aws_user_pools_web_client_id": "1fev6ufppk4ed427530cl9o6s7",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "farusers2163618ca7c74c45ad222fe1c9e5058a171301-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
